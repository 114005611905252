.radiobutton {
  .circle {
    border: 2px solid var(--clr-primary);
    border-radius: 90px;
    width: 27px;
    height: 27px;
    background-color: white;

    span {
      background-color: transparent;
      border-radius: 90px;
      height: 0;
      width: 0;
      margin: 11px;
      display: block;
      transition: all ease-in-out 0.3s;
    }

    &.checked span {
      background-color: var(--clr-primary);
      width: 17px;
      height: 17px;
      margin: 3px;
    }
  }
}
