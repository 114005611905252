.rss-feed {
  &__item {
    border: 2px solid var(--clr-primary);
    border-radius: var(--border-radius);
    padding: 2rem;
    display: block;
    margin-block-end: 2rem;
    text-decoration: none;

    // box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
    box-shadow: var(--box-shadow);
  }
}
