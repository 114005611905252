@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.streamfield.container-field {
  padding-block: 3rem;
  padding-inline: 2rem;
  background: var(--bg-color, transparent);
  border-radius: var(--border-radius);

  @include mq(intermedium) {
    padding-block: 5rem;
    padding-inline: 5rem;
  }

  & > .container {
    margin: 0 auto;
  }

  & > .streamfield {
    border: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background: transparent;
  }

  .container {
    width: min(100% - 2rem, 1200px);

    @include mq(nearsmall) {
      width: min(100% - 2rem, 1200px);
    }
  }

  --checked-image-url: url('../../../images/checked_green.svg');

  &--light-grey,
  &--light-blue,
  &--gradient-light-blue-dark-blue {
    .richtext.streamfield ul {
      margin-left: 6px;

      @include mq(intermedium) {
        margin-left: 30px;
      }

      li {
        // list-style-image: url('../../../images/checked.svg');
        list-style: none;
        position: relative;
        margin-bottom: 20px;
        padding-left: 1rem;

        &::before {
          content: '';
          display: inline-block;
          height: 65px;
          width: 59px;
          background-image: var(--checked-image-url);
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: -72px;
          top: -9px;
          position: absolute;
        }
      }
    }
  }

  &--full-width {
    width: auto;
  }

  &--light-grey {
    --bg-color: var(--clr-lighter-grey);
    --checked-image-url: url('../../../images/checked_green.svg');
    // border: 1px solid #979797;
  }

  &--light-blue {
    --bg-color: var(--clr-light-blue);
    --checked-image-url: url('../../../images/checked_white.svg');
  }

  &--gradient-light-blue-dark-blue {
    --bg-color: linear-gradient(to bottom, var(--clr-lighter-blue), var(--clr-glowing-blue));
    --checked-image-url: url('../../../images/checked_white.svg');
  }

  &--gradient-light-blue-white {
    --bg-color: linear-gradient(to bottom, var(--clr-light-blue), var(--clr-white));
  }

  &--gradient-dark-green-green {
    --bg-color: linear-gradient(to right, var(--clr-clear-blue-green), var(--clr-green-energy));
    color: var(--clr-white);

    & h2,
    h3,
    h4 {
      color: var(--clr-white);
    }
  }
}

.container-field:not(.container-field--gradient-light-blue-dark-blue) .richtext .text-center a {
  text-decoration: none;
  --border-color: var(--clr-primary);
  color: var(--clr-white);
  background-color: var(--clr-primary);
  border: 2px solid var(--border-color);
  padding: 0.5em 2em;
  border-radius: var(--border-radius, 15px);
  cursor: pointer;
  transition: box-shadow 0.2s ease;

  &:focus-visible {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &:hover {
    box-shadow: var(--button-shadow);
  }
}

.container-field:not(.container-field--gradient-light-blue-dark-blue)
  .richtext
  .text-center:has(a) {
  margin-top: 2rem;
}
