@use '../../abstracts/mixins' as *;

.contact {
  // display: flex;
  // gap: 1rem;
  display: grid;
  grid-template-columns: 164px 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  margin-bottom: 2rem;
  hyphens: auto;

  &.nogrid {
    display: block;
  }

  @include mq(medium) {
    row-gap: 0;
  }

  > * {
    font-size: var(--fs-450);
  }

  &__image {
    // flex-shrink: 0;
    grid-column: 1/2;

    img {
      border-radius: 10px;
      object-fit: cover;
      width: 164px;
    }
  }

  &__name {
    font-weight: 500;
  }

  &__phone {
    margin-block-end: 1rem;

    span {
      font-weight: 500;
    }
  }

  &__mail {
    grid-column: 1/3;

    a {
      margin-inline: inherit;
      width: fit-content;
    }

    @include mq(medium) {
      grid-column: 2/3;
    }
  }

  .textwrapper {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: flex-start;
  }

  .button {
    padding: 0.4rem 2.125rem;
    font-size: var(--fs-350);
    // position: relative;
    // top: -6px;
  }
}

.contact-groups__group {
  @include mq(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.contact-groups__group-container {
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.contact-groups__group-title {
  margin-bottom: 2rem;
}
