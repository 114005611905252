@use '../../abstracts/mixins' as *;

.bulletpoints__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.5rem;
  }
}

.bulletpoint {
  display: flex;
  gap: 2rem;
}

.bulletpoint__headline {
  margin-bottom: 1rem;
}

.bulletpoint__svg {
  margin-top: 0.25rem;
}

.bulletpoints__block-title {
  margin-bottom: 0rem;
}

.bulletpoints__block-subtitle {
  margin-bottom: 2rem;
  font-size: var(--fs-700);

  @include mq(small) {
    margin-bottom: 3rem;
  }
}
