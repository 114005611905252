@use '../abstracts/mixins' as *;

.footer {
  text-align: center;
  background: linear-gradient(to bottom, var(--clr-glowing-blue), var(--clr-white));
  color: var(--clr-black);
  font-size: 1.5625rem;

  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  .container {
    padding: 0;
  }

  a {
    color: var(--clr-black);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    &--grid {
      text-align: left;
      display: grid;

      grid-template-rows: 1fr;

      grid-template-columns: 1fr;

      @include mq(intermedium) {
        grid-template-columns: 1fr 1fr;
      }

      gap: 60px;
      height: 100%;

      .icons {
        margin-block-start: 2rem;

        .wrapper {
          display: flex;
          // flex-direction: column;
          justify-content: space-between;

          @include mq(small) {
            flex-direction: row;
            justify-content: inherit;
          }
        }

        a {
          display: flex;
          flex-direction: column;
          text-align: center;

          @include mq(small) {
            display: block;
          }

          @include mq(nearsmall) {
            width: 29%;
          }

          &.eventlink svg {
            width: 77px;
            height: 100px;

            @include mq(nearsmall) {
              width: 104px;
              height: 150px;
            }
          }
        }

        svg {
          width: 100px;
          height: 100px;

          @include mq(nearsmall) {
            height: 150px;
            width: 150px;
          }
        }

        div {
          font-size: 14px;
          font-weight: 500;
        }
      }

      a[href^='tel:'] {
        display: inline;
      }

      span {
        display: inline-flex;
        width: 150px;
      }

      .right {
        a:not([href^='tel:']) {
          text-decoration: underline;
        }
      }
    }
  }

  &__text:after {
    content: '';
    background: var(--clr-black);
    height: 2px;
    width: 100%;
    display: block;
    max-width: 330px;
    margin: 40px auto;
  }

  &__menu {
    margin-top: 40px;

    a {
      font-size: var(--fs-400);
    }
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    list-style: none;

    @include mq(medium) {
      flex-direction: row;
      gap: 2em;
    }
  }

  &__menu-external-link {
    position: relative;

    &:after {
      height: 14px;
      width: 14px;
      display: block;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EExternal Link%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer2' fill='none' stroke='%23000000' stroke-miterlimit='10' stroke-width='8' d='M30 62h32V2H2v32' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3Cpath data-name='layer1' fill='none' stroke='%23000000' stroke-miterlimit='10' stroke-width='8' d='M26 56V38H8m18 0L2 62' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
      position: absolute;
      right: -19px;
      top: 8px;
    }
  }

  &__copy {
    font-size: var(--fs-400);
  }

  &__social {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .socialicon {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.footer__link {
  margin-block-end: var(--flow-spacer, 0.75em);
}
