@use '../abstracts/mixins' as *;

.search {
  margin-block-start: 2rem;
  padding-block-end: 5rem;
  background: var(--clr-white);
  border-bottom: 3px solid var(--clr-primary);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  > .wrapper {
    margin: 0 auto;

    @include mq(small) {
      max-width: 90%;
    }

    @include mq(medium) {
      width: 57rem;
      max-width: 100%;
    }
  }

  &__title {
    text-align: center;
    margin: 0 0 3rem 0;
  }

  &__link-container {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
  }

  &__link {
    display: inline-block;
    font-size: var(--fs-400);
    text-decoration: none;
    border: 2px solid var(--clr-primary);
    border-radius: var(--border-radius);
    padding: 0.5em 1.25em;
    margin-inline: initial;

    &:first-of-type {
      border-color: var(--clr-secondary);
      color: #000;
    }

    &:hover,
    &:focus-visible {
      text-decoration: none;
      box-shadow: var(--button-shadow-soft);
    }
  }
}
