.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  overflow: clip;

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 6px;
    width: 100%;
    background: linear-gradient(to right, var(--clr-warm-sun), var(--clr-green-energy));
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__logo {
    height: 2.6rem;
    position: relative;
    top: 0.3125rem;
  }

  &__link {
    display: inline-block;
    padding-block: 1.5rem;
    padding-inline-start: 1.5rem;
  }

  .navwrapper {
    display: flex;
  }
}

.headerwarning {
  background: var(--clr-warning-red);
  color: #fff;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: var(--fs-400);

  .container {
    --spacer: 0;
    padding: 0;
  }
}
