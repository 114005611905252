@use '../abstracts/mixins' as *;

.search-form {
  // display: flex;
  // align-items: center;
  margin: 1.5rem 0 2rem 0;
  gap: 1rem;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: 0 0 1rem rgb(0 0 0 /0.2);
  position: relative;

  & > *:first-child {
    flex: 1;
  }

  &:focus-within {
    outline: 2px solid var(--clr-primary);
  }

  &__input {
    border: none;
    color: var(--clr-dark-grey);
    padding: 0.75rem 1.5rem;
    width: 100%;

    @include mqmax(small) {
      padding-right: 0;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: 300;
      font-size: var(--fs-400);
      color: inherit;
    }
  }

  &__submit {
    background: transparent;
    border: none;
    cursor: pointer;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    position: absolute;
    right: -6px;
    top: 5px;

    svg {
      width: 60px;
      height: auto;
    }
  }
}
