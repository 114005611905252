.ProcessBar {
  position: relative;
  height: 150px;
  margin-block-start: -4rem;
  margin-block-end: 2rem;
  max-width: 34rem;

  .linebetween {
    position: absolute;
    width: 100%;
    top: 30%;
    &::before {
      content: ' ';
      height: 1px;
      width: 100%;
      background-color: var(--clr-primary);
      position: absolute;
      z-index: -1;
      left: 0;
      top: 50%;
    }
  }
  .inline-steps {
    display: flex;
    justify-content: space-between;

    .icon-container {
      width: calc(3rem + 5px);
      height: 68px;
      background-color: white;
      padding: 7px;

      .label {
        font-size: 12px;
        color: var(--clr-primary);
        padding: 0;
        display: block;
      }
      .icon {
        width: calc(3rem + 5px);
        height: 100%;
        border: 5px solid white;
        background: white;

        svg.next {
          transform: rotate(270deg);
        }
        &.active {
          background-color: var(--clr-primary);
        }
        &.done {
          background-color: var(--clr-green-energy);
          border: none;
        }
      }
    }
  }
}
