@use '../../abstracts/mixins' as *;

.services-block__title {
  margin-bottom: 2rem;
}

.services__service-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @include mq(small) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.service__container {
  border: 3px solid var(--clr-primary);
  border-radius: var(--border-radius, 15px);
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.service__icon-container {
  height: 70px;
  display: flex;
  justify-content: center;
}

.service__icon {
  width: 100%;
  height: 68px;
  object-fit: contain;
  object-position: center;
}

.service__content-container {
  flex-grow: 1;
  h3 {
    margin-bottom: 1rem;
  }

  .service__description {
    font-size: var(--fs-400);
  }
}

.service__button-container {
  display: flex;
  justify-content: center;
}

.services__button {
  padding: 0.8125rem 5rem;
}
