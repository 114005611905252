@use '../../abstracts/mixins' as *;

.streamfield.order {
  max-width: 60rem;
}

.order .dataForm,
.order .PaymentForm {
  h2 {
    margin-block-start: 2.5rem;
    margin-block-end: 3rem;

    &.spacer {
      margin-block-start: 6rem;
      margin-block-end: 0;
    }
  }

  .input {
    position: relative;
    margin-block-start: 1.5rem;
  }

  // label {
  //   margin-block-start: 1rem;
  //   font-weight: 400;
  //   color: var(--clr-primary);
  // }

  .input:has([required]) {
    &:before {
      content: '*';
      position: absolute;
      right: 10px;
      top: 62px;
      z-index: 1;
      color: var(--clr-warning-red);
    }

    &:has([data-no-label]):before {
      top: 10px;
    }
  }

  .delivery_address {
    margin-top: 3rem;
  }

  .checkbox__root {
    border: 2px solid var(--clr-primary);
    // margin-block: 1rem;

    // &__root {
    //   border: 2px solid var(--clr-primary);
    //   width: 20px;
    //   height: 20px;
    //   top: 3px;
    //   position: relative;
    // }
    // &__label {
    //   color: var(--clr-primary);

    //   .label__headline {
    //     font-size: 25px;
    //     font-weight: 400;
    //   }
    // }
  }

  .required {
    margin-block-start: 1rem;
    margin-block-end: 2rem;

    span {
      color: var(--clr-warning-red);
    }
  }

  // .street,
  // .town {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }

  // .town {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;

  //   // .input {
  //   //   width: 25%;
  //   // }
  //   // .input:last-child {
  //   //   width: 73%;
  //   // }
  // }

  .InputRow {
    @include mq(small) {
      display: flex;
      gap: 2rem;

      > .input {
        flex: 0 0 calc(50% - 1rem);
      }
    }

    &.greets {
      margin-block-start: 2rem;
    }
  }
}
