.image {
  --spacer: 7.5rem;
  padding: 0;
  margin: var(--spacer) auto;

  .imagecontainer {
    display: inline-block;

    .imagecaption {
      font-size: var(--fs-400);
      font-style: italic;
      display: inline-block;
    }
  }

  & + * {
    margin-block-start: -1rem;
    padding-block-start: 5rem;
  }

  &__element {
    border-radius: var(--border-radius);
    height: auto;
  }

  &--full-width {
    width: auto;

    .imagecontainer {
      display: inherit;

      .imagecaption {
        padding-inline-start: 1rem;
      }
    }
  }

  &--full-width &__element {
    width: 100% !important;
    border-radius: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
