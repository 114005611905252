@use './label';
@use '../../abstracts/mixins' as *;

.fieldset {
  border: none;
  padding: 0;
  display: flex;
  // flex-direction: column;
  gap: 1em;
  // align-items: stretch;

  flex-wrap: wrap;
  justify-content: space-between;

  &__legend {
    @extend .label !optional;
    margin: 0 0 1em 0;
    text-align: center;
    font-size: var(--fs-700);
  }

  &--as-label &__legend {
    // color: hsl(var(--clr-neutral-800));
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: 600;
    display: inline-block;
    padding: 0 1rem;
    margin-block-end: 0.5rem;
    text-align: left;
  }
}

.form-page {
  fieldset.fieldset,
  fieldset.counters {
    margin-top: 3rem;

    @include mq(medium) {
      margin-top: 5rem;
    }

    .counters__legend,
    .fieldset__legend {
      margin: 0 0 0.5em;
    }
  }
}
