@use '../../utilities/container';

.form {
  @extend .container !optional;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;

  // flex-direction: column;
  // align-items: stretch;
  .form-field {
    width: 100%;
    min-width: 250px;

    &--mt {
      margin-top: 2.5rem;
    }

    &--mt-second {
      margin-top: 2.5rem;

      @media (max-width: 870px) {
        margin-top: 0;
      }
    }

    &--error {
      outline: 2px solid var(--clr-warning-red);
      outline-offset: 1rem;
      border-radius: var(--border-radius);

      label,
      legend {
        color: var(--clr-warning-red);
      }
    }

    &__error-message {
      margin-block-start: 1rem;
      color: var(--clr-warning-red);
    }

    &:has(input) {
      @media (max-width: 870px) {
        width: 100% !important;
      }
    }
  }

  &__submit {
    width: max-content;
    margin-inline: auto;
    float: right;
  }
}

.form-page__submit {
  width: 100%;
}

.form-page__submit-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap-reverse;

  & .form__submit,
  .button {
    margin-inline: 0;
  }
}

.form__allreq-text {
  font-size: var(--fs-350);
  margin: 1rem 0 1rem 0;
}
