@use '../abstracts/mixins' as *;

.product-overview.component {
  --borderRadius: 15px;

  @include mq(nearsmall) {
    display: grid;
    grid-template-areas:
      '. . . .'
      'options options options options';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px 15px;
  }

  .product-overview {
    &__product {
      border: 3px solid var(--clr-primary);
      border-radius: var(--borderRadius);
      text-align: center;
      padding: 10px;
      cursor: pointer;
      position: relative;
      transition: box-shadow ease-in 0.2s;

      @include mqmax(nearsmall) {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 1rem;
        align-items: center;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -11px;
        left: calc(50% - 10px);
        border-top: 20px solid;
        border-top-color: transparent;
        border-right: 20px solid transparent;
        rotate: 225deg;
        transition: all ease-in 0.2s;
      }

      &.active {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

        &::after {
          border-top-color: var(--clr-primary);
        }
      }
    }

    &__kachel-title h3 {
      font-weight: 400;
    }

    &__kachel-svg {
      margin-block-start: 20px;
      margin-block-end: 35px;

      @include mqmax(nearsmall) {
        margin-top: -2px;
        margin-bottom: 1px;

        img {
          min-height: 60px !important;
        }
      }

      @include mq(nearsmall) {
        img {
          margin-inline: auto;
        }
      }
    }
  }

  .product-option {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include mq(nearsmall) {
      grid-area: options;
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 15px;
    }

    @include mqmax(nearsmall) {
      margin-block-start: 1.5rem;
      margin-block-end: 3rem;
    }

    &__links-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &__product {
      border: 2px solid var(--clr-primary);
      border-radius: var(--borderRadius);
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 4fr;
      padding: 8px;
      padding-top: 11px;
      cursor: pointer;
      text-decoration: none;
    }

    &__svg {
      text-align: center;
    }

    &__text {
      --background: linear-gradient(to right, var(--clr-clear-blue-green), var(--clr-green-energy));
      background: var(--background);
      color: var(--clr-white);
      border-radius: var(--borderRadius);
      padding: 20px;
      flex-grow: 1;

      @include mq(nearsmall) {
        padding: 50px;
      }

      &.card {
        &-1 {
          --background: linear-gradient(to right, var(--clr-warm-sun), var(--clr-dark-blue));
        }

        &-2 {
          --background: linear-gradient(
            to right,
            var(--clr-clear-blue-green),
            var(--clr-dark-blue)
          );
        }

        &-3 {
          --background: linear-gradient(to right, var(--clr-warm-sun), var(--clr-clear-blue-green));
        }
      }

      a {
        color: var(--clr-white);
      }

      .button.richtext-button {
        margin-block-start: 2rem;
        background: transparent;
        border-color: #fff;
      }

      .text-center {
        text-align: left; //dirty fix for broken draft button
        display: inline-block;
        margin-top: 1rem;
      }
    }

    &__kwh {
      flex-grow: 1;
      border-radius: var(--borderRadius);
      padding: 0;
    }
  }
}

+ .streamfield.product-overview {
  h2 {
    margin-block: 2.5rem;
  }
}

.product-overview__kwh-calc {
  --background: linear-gradient(to right, var(--clr-clear-blue-green), var(--clr-green-energy));
  background: var(--background);
  border-radius: 1rem;
  margin: 0 1rem;
  padding: 20px;
  position: relative;

  @include mq(nearsmall) {
    padding: 50px;
  }

  &:focus-visible {
    outline-offset: -7px;
  }

  &-content {
    background-color: transparent;
    border-radius: var(--border-radius);
    border: 2px solid var(--clr-primary);
  }

  &-content {
    padding-top: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: white;
    display: none;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
    border: 0;

    &--active {
      display: block;
    }

    .option {
      padding: 0.5rem 1rem;

      &:first-child {
        border-top-right-radius: 0.75rem;
        border-top-left-radius: 0.75rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
      }

      &:hover {
        background-color: rgba(0, 157, 227, 0.2);
      }
    }
  }

  .inputwrapper input {
    border: 0;

    &::placeholder {
      color: var(--clr-primary);
      opacity: 0.8;
    }
  }
}

.Order .product-overview__kwh-calc .inputwrapper .button.next {
  right: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Order .product-overview__kwh-calc .inputwrapper {
  border-radius: 1rem;
  margin-block-end: 0.75rem;
}

.product-overview__kwh-calc-text {
  color: var(--clr-white);
  margin-bottom: 1.5rem;

  & strong {
    margin-bottom: 0.75rem;
    display: inline-block;
  }
}

.kwh-calc__description {
  margin-bottom: 1rem;
}

.kwh-calc__content-container {
  position: relative;
}

.product-overview__kwh-calc--gas {
  --background: linear-gradient(to right, var(--clr-warm-sun), var(--clr-dark-blue));
}
