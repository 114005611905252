@use '../../abstracts/mixins' as *;

$arrow-icon-size: 64px;

.bonuswelt {
  padding-left: 1rem;

  @include mq(small) {
    margin: 0 auto;
    max-width: 1200px;
  }

  @include mq(big) {
    --spacer: 5rem;
    padding-left: 0;
  }

  &__description {
    .richtext {
      margin-top: 1rem;
      margin-bottom: 2rem;
      margin-left: 1px;
    }
  }

  &__splide {
    max-width: 100vw;

    @media (min-width: 500px) {
      max-width: calc(100vw - 2rem);
    }

    @include mq(small) {
      margin-right: 0;
    }
  }

  .splide {
    display: block;

    @include mq(small) {
      padding: 0 $arrow-icon-size;
    }

    @include mq(big) {
      padding: 0;
    }

    ul {
      align-items: stretch;
      flex-grow: 0;
      margin-block-start: 1.3rem;
      margin-block-end: 2rem;
    }
  }

  .splide__arrow {
    background-color: transparent;
    width: $arrow-icon-size;
    height: $arrow-icon-size;

    svg {
      fill: none;
      stroke: var(--clr-primary);
      stroke-miterlimit: 10;
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-linecap: round;
      width: $arrow-icon-size;
      height: $arrow-icon-size;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  .bonuswelt_company {
    height: 100%;
    max-width: calc(100vw - 40px);
    width: 100%;
  }

  .splide__pagination {
    position: initial;

    li button {
      &.is-active {
        background-color: var(--clr-primary);
      }

      border: 1px solid var(--clr-primary);
      background-color: var(--clr-white);
      transform: scale(1.4);
      opacity: 1;
      height: 10px;
      width: 10px;
      margin-right: 10px;
    }
  }

  &__button_uebersicht {
    text-align: center;
    margin-top: 30px;
  }

  &__after_slide {
    padding-right: 1rem;
  }
}
