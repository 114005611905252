@use '../../abstracts/mixins' as *;

.streamfield.cta {
  min-height: 34rem;
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  h2 {
    font-size: 1.875rem;
    text-align: left;
  }

  @include mq(medium) {
    min-height: 45.25rem;
  }

  .container {
    z-index: 1;
    padding: 0;

    .box {
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      padding: 2.5rem;
      max-width: 40rem;
      background: rgba(255, 255, 255, 0.9);
    }
  }

  .button {
    margin-top: 2.5rem;
    display: inline-block;
    text-decoration: none;

    &:hover {
      color: var(--clr-white);
    }

    @include mqmax(small) {
      display: block;
      text-align: center;
    }
  }
}

.cta__image {
  object-fit: cover;
}
