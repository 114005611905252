@use '../abstracts/mixins' as *;

.nav {
  --nav-position-top: 99px;

  background: var(--clr-primary);
  width: 100%;
  position: absolute;
  left: -100%;
  top: var(--nav-position-top);
  height: 100vh;
  z-index: 1;
  transition: left 0.2s ease;
  padding-inline: 1.25rem;
  overscroll-behavior: contain;

  @include mq(medium) {
    transition: left 0.2s ease;
  }

  ul {
    padding: 0;
  }

  &--open {
    opacity: 0.95;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    position: fixed;
    overflow-y: scroll;
    left: 0;
    width: 100%;

    @include mq(big) {
      width: calc(100% + 15px);
    }
  }

  .nav__list {
    display: grid;
    justify-content: center;
    padding-left: 0;
    padding-bottom: var(--nav-position-top);
    margin-top: 3.375rem;

    @include mq(big) {
      display: flex;
      flex-wrap: wrap;
      max-width: 90%;
      margin-inline: auto;
      margin-left: 11rem;
    }

    &.topnav > .nav__list-item {
      margin-block-end: 2.5rem;
      padding-block-end: 2.5rem;
      border-bottom: 1px solid #fff;

      @include mq(big) {
        width: 38%;

        border-bottom: none;
        padding-inline-end: 1rem;
      }
    }

    &--category,
    &--maincategory {
      font-weight: 300;
      font-size: var(--fs-800);
    }

    &--category {
      display: flex;
    }

    &--maincategory {
      font-size: var(--fs-600);
      font-weight: 600;
      display: grid;
    }

    &-item {
      list-style: none;
      color: var(--clr-white);
    }
  }

  .nav__sub-menu {
    display: none;
  }

  .nav__link {
    color: var(--clr-white);
    text-decoration: none;
    font-size: 1.25rem;
    transition: all 0.2s ease;
    width: 100%;
    margin-block-start: 1rem;
    display: flex;
    align-items: center;
    text-decoration-color: transparent;
    text-underline-offset: 0;
    gap: 1rem;

    @include mq(small) {
      font-size: var(--fs-500);
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1.875rem;
    }

    @include mq(big) {
      padding-block: 0.5rem;
      padding-left: 0;
      border: none;
    }

    &:hover,
    &.active {
      text-decoration: none;
      color: var(--clr-white);

      @include mq(big) {
        box-shadow: none;
        text-decoration: underline;
        text-underline-offset: 0.6rem;
      }
    }

    &--external:after {
      height: 14px;
      width: 14px;
      display: block;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EExternal Link%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer2' fill='none' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='8' d='M30 62h32V2H2v32' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3Cpath data-name='layer1' fill='none' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='8' d='M26 56V38H8m18 0L2 62' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
      position: relative;
      right: 7px;
      top: 3px;
    }
  }

  .subnav {
    @include mq(big) {
      padding-block-start: 1rem;
    }

    .nav__list {
      &--category {
        margin-block-start: 1rem;
        font-size: 1.25rem;
        font-weight: 600;
        justify-content: center;

        @include mq(big) {
          padding-left: 67px;
          justify-content: left;
          margin-bottom: -1rem;
          margin-top: 2rem;
          opacity: 0.7;
        }
      }
    }

    > .nav__list-item {
      margin-bottom: 3rem;

      @include mq(big) {
        margin-bottom: 0;
        min-height: 4.3rem;
      }

      .button {
        margin-bottom: -26px;
        padding-left: 1rem;
      }
    }
  }

  .searchform {
    background-color: #fff;
    border-radius: 1rem;
    margin-block-start: 2.625rem;
    margin-inline: auto;
    height: 4.25rem;
    display: flex;
    justify-content: space-between;
    max-width: 38rem;

    input {
      border: 0;
      height: 100%;
      width: 100%;
      border-radius: 1rem;
      padding: 0.75rem 1.5rem;
    }

    &__button {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
    }
  }
}
