.message {
  border-radius: var(--border-radius);
  padding: 5rem 2.5rem !important;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background: var(--bg-color);
  color: var(--color);

  &--error {
    --bg-color: hsl(0 50% 75%);
    --color: hsl(0 50% 10%);
    text-shadow: none;
    .message__title {
      color: var(--color);
    }
  }

  &--success {
    --bg-color: var(--clr-clear-blue-green);
    --color: var(--clr-white);
  }

  &--warning {
    --bg-color: hsl(60 35% 70%);
    --color: hsl(60 35% 20%);
    .message__title {
      color: hsl(60 35% 20%);
    }
  }

  &--information {
    --bg-color: var(--clr-glowing-blue);
    --color: var(--clr-white);
  }

  &--grad-ty {
    --bg-color: linear-gradient(to right, var(--clr-clear-blue-green), var(--clr-green-energy));
    --color: var(--clr-white);
  }

  &--grad-tb {
    --bg-color: linear-gradient(to right, var(--clr-clear-blue-green), var(--clr-text-color));
    --color: var(--clr-white);
  }

  &--grad-yl {
    --bg-color: linear-gradient(to right, var(--clr-warm-sun), var(--clr-glowing-blue));
    --color: var(--clr-white);
  }

  &--grad-yb {
    --bg-color: linear-gradient(to right, var(--clr-warm-sun), var(--clr-text-color));
    --color: var(--clr-white);
  }

  &__title {
    font-size: var(--fs-500);
    color: var(--clr-white);
    line-height: 1;
  }

  &__button {
    border: none;
    background: transparent;
    text-decoration: underline;
    --color: var(--clr-white);
    font-size: var(--fs-500);
    padding: 0;
    font-weight: normal;
    &:hover {
      box-shadow: none;
      color: var(--clr-primary);
    }
  }

  a {
    color: var(--color);
  }
}
