@use '../abstracts/mixins' as *;

.banner-image {
  margin-block-end: -12.5%;
  isolation: isolate;
  position: relative;
  z-index: -1;
  height: calc(70vh + 1em);
  max-height: 300px;

  img {
    color: transparent;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    background-color: var(--clr-white);
    height: 1em;
    width: 100%;
    bottom: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
}

@include mq(small) {
  .banner-image {
    max-height: 400px;
  }
}

@include mq(medium) {
  .banner-image {
    max-height: none;
    height: calc(500px + 1em);
  }
}

// placeholder bg if there is no banner image
.banner-image__small {
  background-color: #87ceea;
  max-height: 90px;

  @include mq(small) {
    max-height: 230px;
  }
}
