@use '../../abstracts/mixins' as *;

.columns {
  --grid-gap: 3rem;

  // padding-inline: 0;

  & > * + * {
    margin: var(--grid-gap) 0 0;
  }

  & .streamfield,
  & .container,
  & .image {
    // --spacer: 1rem;
    --spacer: 2.5rem;
  }

  &__grid {
    @include mqmax(small) {
      > div {
        margin-block-end: 2rem;
      }

      > div:last-of-type {
        margin-block-end: 0;
      }
    }

    @include mq(nearsmall) {
      display: grid;
      gap: var(--grid-gap);
    }

    &[data-type='column_2_1'] {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }

    &[data-type='column_1_1'] {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }

    &[data-type='column_1_1_1'] {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
  }

  .streamfield.image .image__element {
    margin-inline: auto;
    height: auto;
  }
}
