@use '../abstracts/mixins' as *;

.search-results {
  &__categories {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @include mq(small) {
      gap: 2rem;
      flex-direction: row;
    }

    .button {
      padding: 0.5em 1em;
      flex: 1;

      @include mqmax(small) {
        margin-inline: inherit;
      }
    }
  }

  &__item {
    padding-block-start: 1.5em;
    padding-block-end: 1.5em;
  }

  &__list {
    list-style: none;
    // margin-block-start: 2em;
    padding-inline-start: 0;

    > * + * {
      // margin-block-start: 2.5em;
      border-top: 2px solid;
    }
  }

  &__link {
    display: inline-block;
    text-decoration: none;

    + .flow {
      font-size: var(--fs-400);
    }
  }

  &__title {
    font-size: var(--fs-500);
    margin-block-end: 0;
  }

  &__descreet {
    font-size: var(--fs-400);
    color: rgb(161, 161, 161);
    margin-block-start: -0.3em;
  }

  &__description {
    margin-block-start: 0.5rem;
    // Trick for auto-shorten the test
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
