.newsletter-register {
  .data-protection {
    .text-normal {
      font-size: var(--fs-400);
      display: inline-block;
    }
  }

  form {
    width: 100%;
  }

  .form-field {
    sup {
      color: var(--clr-required-red);
    }
  }
}

.newsletter-confirm {
  max-width: 64rem;

  form {
    display: flex;
    justify-content: center;
  }
}

.newsletterstate.message {
  margin-block-start: 2rem;
  margin-inline: auto;
  width: fit-content;
}
