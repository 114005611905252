.richtext {
  a {
    word-break: break-word;
  }

  .text-center a {
    display: inline-flex;
    text-decoration: none;
    --border-color: var(--clr-white);
    --color: var(--clr-white);
    color: var(--color);
    background-color: var(--bg-color);

    border: 2px solid var(--border-color);
    padding: 0.5em 2em;
    border-radius: var(--border-radius, 15px);
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:focus-visible {
      outline: 2px solid var(--border-color);
      outline-offset: 2px;
    }

    &:hover {
      box-shadow: var(--button-shadow-soft);
    }
  }

  &.stellen {
    p a {
      border: 2px solid var(--clr-primary);
      border-radius: var(--border-radius);
      padding: 2rem;
      display: block;
      margin-block-end: 2rem;
      text-decoration: none;

      // box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
      box-shadow: var(--box-shadow);
    }
  }

  .richtext-image.full-width {
    margin-inline: auto;
  }

  .button.richtext-button {
    display: flex;
    max-width: fit-content;

    a {
      text-decoration: none;
    }
  }
}

.button--primary.richtext-button {
  display: flex;
  max-width: fit-content;

  a {
    color: var(--clr-white);
    text-decoration: none;
  }
}
