@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  --flow-spacer: 4rem;

  @extend .container !optional;
  margin: 2em auto;
  padding-block-start: 2rem;

  background: var(--clr-white);
  border-radius: var(--border-radius);

  .wrapper {
    margin: 0 auto;

    @include mq(small) {
      max-width: 90%;
    }

    @include mq(medium) {
      width: 60rem;
      max-width: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__icon,
  &__svg {
    text-align: center;

    svg {
      height: 100px;
      width: auto;
    }
  }

  &__subtitle {
    --flow-spacer: 0.5rem;
    font-weight: 400;
    font-size: var(--fs-500);
  }

  &__text {
    font-size: var(--fs-500);
    margin-inline: auto;
  }
}

@include mq(medium) {
  .hero {
    // min-height: 166px; // added 16 px to min height to avoid gap between banner and content when subheading et al. is missing
    min-height: 180px;
    padding-block-start: 5rem;
    padding-left: 43px;

    &__subtitle {
      --flow-spacer: 1rem;
    }

    h1 {
      font-size: 3.3125rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
}

@media only screen and (min-width: 2000px) {
  .hero-content {
    height: calc(500px + 1em);
    position: relative;
  }

  .hero-content .hero {
    position: absolute;
    bottom: -2rem;
    left: calc(50% - 600px);
    width: 1200px;
  }
}
