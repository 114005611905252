.checkbox {
  display: flex;
  align-items: initial;

  &--baseline {
    align-items: flex-start;
  }

  &__root {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 3px solid var(--clr-primary);
    cursor: pointer;
    flex-shrink: 0;
    margin-top: 2px;

    &:focus-visible {
      box-shadow: 0 0 0 6px hsl(var(--clr-neutral-800));
    }
  }

  &__indicator {
    color: hsl(var(--clr-neutral-800));
    height: 24px;
  }

  &__label {
    // color: hsl(var(--clr-neutral-800));
    padding: 0 0 0 0.5em;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    line-height: 1.4;
    cursor: pointer;
    user-select: none;
    font-weight: 300;

    &--small {
      font-size: clamp(1rem, 4vw, 1.5rem);
    }

    .label {
      &__headline {
        font-weight: 500;
      }

      &__help_text {
        font-size: initial;
      }
    }

    a {
      color: inherit;
      font-weight: 600;
    }
  }
}
