@use '../abstracts/mixins' as *;
$magazins-arrow-icon-size: 64px;

.magazins {
  h2 {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .magazin-list-item {
    border-bottom: 1px solid var(--clr-primary);

    &:last-child {
      border-bottom: none;
    }

    a {
      text-decoration: none;
    }
  }
}

.magazin {
  @include mq(nearsmall) {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 250px auto;
    gap: 2px 20px;
    grid-template-areas:
      'image . '
      'image . ';
  }

  margin-top: 30px;
  margin-bottom: 30px;

  &__image {
    grid-area: image;
    position: relative;
    min-height: 200px;

    img {
      border-radius: 15px;
      object-fit: cover;
    }
  }

  &__title {
    font-weight: 500;
    margin-block-start: 0.8em;
    margin-block-end: 0.5em;
  }
}

.streamfield.magazin-slider {
  // max-width: 100vw;
  margin-right: -20px;
  padding-left: 1rem;
  width: calc(100vw + 1rem);
  margin: 0;

  @media (min-width: 500px) {
    max-width: calc(100vw - 1rem);
    margin-right: 0;
  }

  @media (min-width: 75rem) {
    width: min(100% - 2rem, 1200px);
    margin: 0 auto;

    .slide__list {
      justify-content: center;
    }
  }

  @include mq(big) {
    padding-left: 0;
  }

  > .richtext {
    --spacer: 0;
  }

  .magazin.component {
    grid-template-columns: none;
    grid-template-areas:
      'image'
      '.'
      '.';

    .magazin__image {
      height: 250px;
    }
  }

  .button-row {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
}

.magazin-slider__splide {
  // max-width: calc(100vw - 35px);

  .splide__arrow {
    background-color: transparent;
    width: $magazins-arrow-icon-size;
    height: $magazins-arrow-icon-size;

    svg {
      fill: none;
      stroke: var(--clr-primary);
      stroke-miterlimit: 10;
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-linecap: round;
      width: $magazins-arrow-icon-size;
      height: $magazins-arrow-icon-size;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  .splide {
    display: block;

    @include mq(small) {
      padding: 0 $magazins-arrow-icon-size;
    }

    @include mq(big) {
      padding: 0;
    }
  }

  .splide__pagination {
    position: initial;

    li button {
      &.is-active {
        background-color: var(--clr-primary);
      }

      border: 1px solid var(--clr-primary);
      background-color: var(--clr-white);
      transform: scale(1.4);
      opacity: 1;
      height: 10px;
      width: 10px;
      margin-right: 10px;
    }
  }
}
