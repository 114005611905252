.hamburger {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.625rem;
  background: transparent;
  padding: 1.5rem;
  border: none;
  cursor: pointer;
  width: 6.25rem;

  span {
    height: 0.25rem;
    width: 100%;
    border-radius: 20px;
    background: var(--clr-primary);
  }

  &::before,
  &::after {
    content: '';
    height: 0.25rem;
    background: var(--clr-primary);
    width: 100%;
    position: relative;
    transition: all ease 0.2s;
    border-radius: 20px;
  }

  &::before {
    left: 0;
  }

  &::after {
    top: 0;
    left: 0;
  }
}

.nav--open + .hamburger {
  z-index: 10;
  &::before {
    top: 0.9375rem;
    transform: rotate(45deg);
    // background-color: #fff;
  }

  &::after {
    transform: rotate(-45deg);
    top: -0.75rem;
    // background-color: #fff;
  }

  span {
    opacity: 0;
  }
}
