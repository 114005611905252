@use '../abstracts/mixins' as *;

body {
  font-size: var(--fs-450);
  color: var(--clr-text-color);
  font-weight: 300;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-primary);
}

h1 {
  color: var(--clr-primary);
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--fs-750);
  line-height: 2.8125rem;
  font-weight: 500;
  text-wrap: balance;

  @include mq(small) {
    line-height: 3.75rem;
  }
}

h2,
.h2 {
  font-size: var(--fs-700);
  line-height: 2.5rem;
  font-weight: 500;
  margin-block-end: 1rem;
  text-wrap: balance;

  @include mq(small) {
    line-height: 3.125rem;
  }
}

h3,
.h3 {
  text-wrap: balance;
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

h4,
.h4 {
  text-wrap: balance;
  font-size: 25px;
  line-height: 1.4;
  font-weight: 500;
}

small,
.text-small {
  font-size: var(--fs-300);
}

.text-normal {
  font-weight: 300;
}

strong {
  font-weight: 700;
}

p {
  hyphens: auto;
}

a {
  color: var(--clr-primary);

  &:hover {
    color: var(--clr-primary);
    text-decoration: underline;
  }

  &:focus-visible {
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: 600;
}

// change selection color bc of blue container bg
::selection {
  background-color: var(--clr-warm-sun);
}

ul.ya {
  padding-left: 35px;

  li {
    list-style-type: none;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      height: 33px;
      width: 33px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow Right%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer2' fill='none' stroke='%23ffcc02' stroke-miterlimit='10' stroke-width='6' d='M50 31.998H14' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3Cpath data-name='layer1' fill='none' stroke='%23ffcc02' stroke-miterlimit='10' stroke-width='6' d='M36 18l14 14-14 14' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      top: 2px;
      position: absolute;
      left: -37px;
    }
  }
}
