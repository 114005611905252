@use '../../abstracts/mixins' as *;

.streamfield.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 3.5rem;

  @include mqmax(small) {
    display: block;
    text-align: center;
  }

  .button {
    text-decoration: none;

    @include mqmax(small) {
      margin-block-end: 1rem;

      &:last-child {
        margin-block-end: 0;
      }
    }

    &--primary:hover {
      color: var(--clr-white);
    }
  }
}

.buttons--center {
  justify-content: center;
}
