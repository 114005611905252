@use '../abstracts/mixins' as *;

.Order {
  .zuruecksetzen {
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .radiobutton {
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: 4px;
  }

  div[role='presentation'] {
    cursor: pointer;
  }

  div[role='button'] {
    cursor: pointer;
  }

  &__subtitle {
    font-size: var(--fs-700);
    font-weight: 600;
  }

  &__order-subtitle {
    margin-bottom: 2rem;
  }

  input {
    border-radius: var(--border-radius);
    border: 1px solid var(--clr-primary);
    padding: 1rem;
  }

  .navigation-row {
    margin-top: 1.5rem;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;

    .button {
      width: 100%;
      max-width: 250px;
    }
  }

  .priceBackround {
    position: relative;
    font-weight: 500;
    display: initial;
    top: 5px;

    &::before {
      content: ' ';
      background-color: var(--clr-warm-sun);
      height: 50%;
      width: 100%;
      position: absolute;
      z-index: -1;
      bottom: 0;
    }
  }

  .order__edit-kwh-button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0 0.5rem;
  }

  .order__edit-kwh-svg {
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;

    & path {
      stroke: var(--clr-text-color);
    }
  }

  .KwhCalculator {
    form {
      margin-inline: -1rem;
    }

    .inputwrapper {
      position: relative;
      padding-inline: var(--padding);
      margin-block-end: 1rem;

      input {
        width: 100%;
        padding-right: 70px;
        color: var(--clr-primary);
      }

      .button.next {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow Right%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer2' fill='none' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='3' d='M50 31.998H14' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3Cpath data-name='layer1' fill='none' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='3' d='M36 18l14 14-14 14' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 45px;
        background-position: center center;
        position: absolute;
        right: 24px;
        top: 0;
        height: 100%;
        width: 70px;
        padding: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .accordion {
      padding-inline: 1rem;
      padding-block-start: 1rem;
      padding-block-end: 1.875rem;
      background-color: transparent;

      &:focus-visible {
        outline-offset: -7px;
      }

      &__header,
      &__content {
        background-color: #fff;
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius);
      }

      &__header {
        border: none;
        margin-block-end: 0.5rem;

        button {
          font-size: var(--fs-450);
          font-weight: 400;
        }
      }

      &__content {
        padding-block-start: 1rem;
        padding-inline: 0;

        .option {
          padding-inline: var(--padding);
          padding-block: 0.5rem;

          &:hover {
            background-color: var(--clr-light-blue);
          }
        }
      }
    }

    .business-customer {
      h2 {
        margin-top: 20px;
      }

      .seperator {
        height: 2px;
        width: 100%;
        margin-block: 1rem;
        background-color: lightgray;
      }

      .contact-item {
        display: grid;
        grid-template-columns: 120px 1fr;
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
          'image name'
          'image department'
          'image phone'
          'image mail';
        grid-gap: 0px 20px;

        &__image {
          grid-area: image;

          .image_container {
            height: 180px;
            width: 120px;
            background-color: lightgrey;
            border-radius: 15px;
          }
        }

        &__name {
          grid-area: name;
          font-weight: 500;
        }

        &__department {
          grid-area: department;
        }

        &__phone {
          grid-area: phone;
        }

        &__mail {
          grid-area: mail;
        }
      }
    }

    .option {
      padding-top: 1rem;
    }

    & .KwhCalculator__radio-container {
      display: flex;
      gap: 0.5rem 2rem;
      flex-wrap: wrap;
      margin: 1.5rem 0;
    }
  }

  .ContractPeriod {
    .option {
      border: 3px solid var(--clr-primary);
      border-radius: 15px;
      margin-bottom: 1.5rem;
      padding: 10px;
      padding-left: 0.5rem;
      cursor: pointer;
      display: grid;
      align-items: center;
      grid-gap: 1rem;
      grid-template-columns: 50px 1fr;

      @include mq(small) {
        padding: 1.5rem;
      }

      .wrapper {
        @include mq(small) {
          display: flex;
          width: 100%;
          justify-content: space-between;
          font-size: var(--fs-700);
        }
      }

      &.inactive {
        opacity: 0.7;
        transition: ease-in-out opacity 0.2s;
        padding-left: 0.5rem;

        @include mq(small) {
          padding-left: 1.5rem;
        }
      }

      &__radio {
        // grid-area: button;
        width: 4rem;
        display: flex;
        justify-content: center;

        .radiobutton {
          position: inherit;
          margin: 0;
        }
      }

      &__label {
        font-size: var(--fs-450);
      }

      &__price {
        font-size: var(--fs-350);

        @include mq(small) {
          font-size: var(--fs-450);
        }
      }
    }

    .feature-container {
      margin-block-start: 3rem;
    }
  }

  .TarifDetail,
  .OrderOverview {
    .order-overview__edit-container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-block-end: 1rem;
      margin-block-start: 2rem;

      & h3 {
        margin: 0;
      }
    }

    & .productitem-wrapper .order-overview__edit-container {
      margin-block-start: 0;
    }

    .order-overview__edit-button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0.25rem 0.25rem;
      margin-left: 0.5rem;
    }

    .order-overview__edit-icon {
      width: 24px;
      height: 24px;
      object-fit: contain;
      object-position: center;

      & path {
        stroke: var(--clr-text-color);
      }
    }

    .order-overview__sideselling-text {
      margin-bottom: 1.25rem;
    }

    .detail {
      margin-block-end: 1.5em;
    }

    .detail-text {
      margin-block: 1.5em;
      padding-block: 1.5em;
      border-top: 3px solid var(--clr-primary);
      border-bottom: 3px solid var(--clr-primary);
    }

    .accordion {
      margin-top: 2.5rem;
      margin-bottom: 3rem;
    }

    .downloads {
      padding-left: 0;
      line-height: 1.6;

      li {
        list-style: none;

        a {
          text-decoration: none;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 1.3em;
          width: 1.3em;
          margin-right: 7px;
          position: relative;
          top: 7px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAlign Bottom%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer2' fill='none' stroke='%23134094' stroke-miterlimit='10' stroke-width='5' d='M24 36l8 9 8-9m-8-26v35' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3Cpath data-name='layer1' fill='none' stroke='%23134094' stroke-miterlimit='10' stroke-width='5' d='M12 54h40' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }
  }

  .DataForm {
    .customer-type {
      margin-block-end: 1.3rem;
    }

    .checkbox {
      margin-top: 1.5rem;
    }

    h2 {
      margin-top: 1.5rem;
    }
  }

  .OrderOverview {
    .productitem-wrapper {
      @include mq(small) {
        display: flex;
        justify-content: space-between;

        .product-item {
          flex: 1 1 50%;
        }
      }

      .OrderOverview__trash-svg {
        width: 36px;
        height: 36px;
        object-fit: contain;
        color: var(--clr-primary);

        & path {
          stroke-width: 5;
        }
      }

      .OrderOverview__contract-container {
        display: flex;
        gap: 3rem;
        align-items: flex-end;
        margin-block-start: 2rem;
      }

      & h3 {
        margin-block-start: 0;
      }
    }

    h3 {
      margin-block-start: 2rem;
      margin-block-end: 1rem;
    }

    & h3.accordion__header {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .item-container {
      margin-bottom: 2rem;
      line-height: 2.5rem;

      &--space {
        margin-bottom: 3rem;
        margin-top: 3rem;
      }
    }

    .OrderOverview__button-container {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem 3rem;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 1rem;
    }

    .productitem-total__container {
      border-top: 3px solid var(--clr-primary);
      margin-bottom: 4rem;
    }

    // .button {
    //   width: 100%;
    // }

    .tariff-info {
      margin-top: 2rem;
      margin-bottom: 3rem;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: repeat(1, 1fr);
      grid-template-areas: 'image text';

      &__logo {
        grid-area: image;
        margin: auto;
        width: 80px;
      }

      &__text {
        grid-area: text;
      }
    }
  }

  .ThankYou {
    text-align: center;

    .icon {
      margin: auto;
      max-width: 150px;
    }

    & h2 {
      font-size: var(--fs-600);
    }

    & .thank-you__mail {
      font-size: var(--fs-500);
    }
  }

  .delivery_address {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.Sideselling {
  .sideselling-container {
    // width: 70%;
    margin-inline: auto;
    margin-block: 2rem;
    // background-color: var(--clr-lighter-grey);
    border-top: 3px solid var(--clr-primary);

    padding: 10px;
    padding-top: 2rem;

    .button {
      margin-block: 1rem 3rem;
    }

    .KwhCalculator {
      .accordion .button {
        margin: 0;
      }

      & h3.accordion__header {
        margin-block-end: 0.5rem;
      }
    }
  }
}

.PaymentForm {
  .checkbox-row {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.order-opt-in-page {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
