@use '../abstracts/mixins' as *;

.button {
  --bg-color: var(--clr-white);
  --border-color: var(--clr-primary);
  --color: var(--clr-primary);

  font-size: 1.25rem;
  color: var(--color);
  background-color: var(--bg-color);
  border: 2px solid var(--border-color);
  padding: 0.8125rem 2.6rem;
  text-decoration: none;
  border-radius: var(--border-radius, 15px);
  transition: box-shadow ease 0.2s;
  cursor: pointer;

  @include mqmax(small) {
    display: block;
    margin-inline: auto;
  }

  &:focus-visible {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &:hover {
    box-shadow: var(--button-shadow);
    text-decoration: none;
  }

  &--primary {
    --bg-color: var(--clr-primary);
    --color: var(--clr-white);

    &:disabled {
      --bg-color: var(--clr-white);
      --color: var(--clr-dark-grey);
      --border-color: var(--clr-light-grey);
      pointer-events: none;

      &:hover {
        color: var(--clr-dark-grey);
      }
    }

    &:hover {
      color: var(--clr-white);
    }
  }

  &--secondary {
    --bg-color: var(--clr-secondary);
    --border-color: var(--clr-secondary);
    --color: var(--clr-white);
  }

  &--light {
    --bg-color: transparent;
    --border-color: var(--clr-white);
    --color: var(--clr-white);
    display: inline-block;
  }
}

.button--delete {
  font-size: 1.25rem;
  color: var(--color);
  background-color: transparent;
  padding: 1rem;
  text-decoration: none;
  border-radius: var(--border-radius, 15px);
  border: none;
  transition: box-shadow ease 0.2s;
  cursor: pointer;
  display: inline-flex;

  &:focus-visible {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &:hover {
    box-shadow: var(--button-shadow);
    text-decoration: none;
  }
}

.button--umzug {
  display: inline-block;
  @media (min-width: 1132px) {
    margin-top: 44px;
  }
}

.container-field .button:not(.button--primary) {
  --bg-color: transparent;
}

.gdpr-popover__trigger {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: 0;
  background-color: transparent;
  z-index: 1000;
  cursor: pointer;
}
