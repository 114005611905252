@use './label';

.counters {
  border: none;
  padding: 0;

  flex-wrap: wrap;
  justify-content: space-between;

  &__entry {
    position: relative;
    margin-block: 2rem;

    &:not(:last-of-type)::after {
      content: '';
      display: block;
      display: block;
      border-top: 1px solid var(--clr-primary);
      margin-block: 2rem;
    }
  }

  &__legend {
    @extend .label !optional;
    margin: 0 0 1em 0;
    text-align: center;
    font-size: var(--fs-700);
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-block-end: 1rem;
  }

  &__types {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-block-end: 1rem;
    border: none;
    padding: 0;

    legend {
      font-size: clamp(1.25rem, 4vw, 1.5rem);
      font-weight: 600;
      display: inline-block;
      padding: 0 1rem;
      margin-block-end: 0.5rem;
      text-align: left;
    }
  }

  &__radio {
    display: inline-flex;
    align-items: center;
    accent-color: var(--clr-primary);
    gap: 0.5rem;

    input[type='radio'] {
      width: 1em;
      height: 1em;
    }
  }

  &__delete-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    border: none;
    height: 1em;
    background-color: transparent;
    width: fit-content;
  }
}
