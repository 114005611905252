.prices {
  .cards__wrapper {
    gap: 4rem;
    padding: 0;
  }

  .price {
    text-align: left;
    border-radius: var(--border-radius);
    border: 3px solid var(--clr-primary);
    box-shadow: var(--box-shadow);

    &__title {
      margin-block-end: 1rem;
    }

    &__subtitle {
      margin-block-end: 1rem;
    }

    &__text {
      ul {
        margin-block-end: 4rem;

        li {
          list-style: none;
          position: relative;
          margin-bottom: 20px;
          padding-left: 1rem;

          &:before {
            content: '';
            display: inline-block;
            height: 65px;
            width: 59px;
            background-image: url('../../../images/checked_green.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: -72px;
            top: -9px;
            position: absolute;
          }
        }
      }
    }

    &__link {
      margin-top: auto;
      text-decoration: underline;
      font-weight: 600;
      text-align: center;
    }
  }

  &__button {
    text-align: center;
    margin-top: 30px;
  }

  &__bottomText {
    margin-top: 2rem;
  }
}
