@use '../../abstracts/mixins' as *;

.featuretiles {
  padding: 0;
  text-align: center;

  // h2 {
  //   font-weight: 300;
  //   margin-bottom: 3.75rem;
  // }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5625rem;
    flex-wrap: wrap;
    flex-direction: column;

    @include mq(intermedium) {
      flex-direction: row;
      align-items: baseline;
    }
  }

  .featuretile {
    display: flex;
    flex-direction: column;
    flex: 0 0 33%;
    width: 100%;
    max-width: 350px;

    &__bgwrapper {
      padding-top: 4.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 1.875rem;
      background-color: var(--clr-white);
      border: 4px solid var(--clr-primary);
      border-radius: var(--border-radius);

      .card__iconwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__icon {
      svg {
        height: 120px;
      }
    }

    &__text {
      font-size: var(--fs-900);
      font-weight: 300;

      &--small {
        font-size: var(--fs-450);
      }
    }
  }
}
