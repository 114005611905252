.label {
  // color: hsl(var(--clr-neutral-800));
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 600;
  display: inline-block;
  padding: 0 1rem;
  margin-block-end: 0.5rem;
}

.label__container {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}
