.textarea {
  textarea {
    width: 100%;
    border-radius: var(--border-radius);
    border-color: var(--clr-primary);
    resize: vertical;
    min-height: 200px;
    padding: 1rem;
    font-size: var(--fs-400);
    color: var(--fs-primary);

    :focus {
      outline-color: var(--clr-primary);
    }
  }
}
