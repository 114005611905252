@use '../abstracts/mixins' as *;

.magazin-card {
  border: 3px solid var(--clr-primary);
  padding: 0.5em 2em 0.9em;
  border-radius: var(--border-radius, 15px);
  text-decoration: none;

  > a {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-decoration: none;
  }

  .magazin {
    display: block;
    @include mq(medium) {
      display: grid;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.article__share {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
}

.article__share-button {
  background-color: transparent;

  color: var(--clr-text-color);
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;

  & svg {
    stroke: var(--clr-text-color);

    & path {
      stroke: var(--clr-text-color);
    }
  }

  &.copy {
    position: relative;
  }

  .article__share-copy-tooltip {
    display: none;
    position: absolute;
    top: -5rem;
    right: 0;
    z-index: 1000;
    width: 300px;
    background-color: #222223;
    color: white;
    padding: 0.25rem;
    border-radius: 0.5rem;

    &.visible {
      display: block;
    }
  }
}
