@use '../abstracts/mixins' as *;

.contactnav {
  --nav-position-top: 99px;

  background: var(--clr-primary);
  color: #fff;
  width: 100%;
  position: absolute;
  left: -100%;
  top: var(--nav-position-top);
  height: 100vh;
  z-index: 1;
  transition: left 0.2s ease;
  padding-inline: 2rem;
  padding-block-start: 3.125rem;
  padding-block-end: 6rem;
  overscroll-behavior: contain;

  @include mq(big) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem 4rem; // adjusted for second text in contactnav, originally gap: 4rem

    // width: calc(100% + 15px);
  }

  ul {
    padding: 0;
  }

  p {
    margin-block: 2rem;
    font-size: 1.125rem;
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
    padding-block-start: 0.875rem;
  }

  &--open {
    opacity: 0.95;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    position: fixed;
    overflow-y: scroll;
    left: 0;

    @include mq(big) {
      width: calc(100% + 15px);
    }
  }

  &__block {
    display: grid;
    padding-block-end: 3.5rem;
    align-content: baseline;
    border-bottom: 1px solid var(--clr-white);

    &--head {
      font-weight: 600;
      font-size: var(--fs-600);
      display: grid;
      align-content: baseline;

      @include mq(big) {
        &.callme {
          margin-block-end: 3.3125rem;
        }

        &.emergency {
          margin-block-end: 3.3125rem;
        }
      }
    }

    &--item {
      display: grid;
      // grid-template-columns: minmax(33px, auto) 1fr;
      grid-template-columns: 33px minmax(270px, 1fr);
      gap: 1rem;
      align-items: start;
      font-size: 1.125rem;
      margin-block-start: 1rem;

      .text {
        display: grid;
      }

      a {
        color: var(--clr-white);
        text-decoration: none;
      }
    }
  }

  .note {
    width: 100%;
    text-align: center;
  }
}

.contactnav__info-block {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  max-width: 100%;
  font-size: 1.125rem;

  @include mq(big) {
    max-width: 270px;
    margin-top: 0;
  }
}
