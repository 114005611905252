*:focus:not(:focus-visible) {
  outline: none;
}
*:focus-visible {
  outline: 2px dashed currentColor;
  outline-offset: 2px;
}

video {
  border: 1px solid var(--clr-primary);
  border-radius: var(--border-radius);
}
